import Vue from 'vue';
import Homepage from './Homepage.vue';
import '~/registerServiceWorker'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

Wormhole.trackInstances = false

import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 3,
  // set observer to true
  observer: true,
  adapter: {
    loaded({ bindType, el, naturalHeight, naturalWidth, $parent, src, loading, error, Init }) {
      el.parentElement.classList.add('animate__animated', 'animate__fadeIn');
    }
  }
});

import VueRouter from 'vue-router'

import router from '~/router';
import store from '~/store';

import PortalVue, { Wormhole } from 'portal-vue';
Vue.use(PortalVue);
Wormhole.trackInstances = false;

if (typeof process === 'undefined' || !process || !process.env || process.env.NODE_ENV !== 'test') {
  Vue.use(VueRouter)
}

/**
 * Set environment variable based on location
 */
const SOLE_HOST = window.location.host.toString();
const SOLE_ENV = (SOLE_HOST === "yoursole.com")
  ? "production"
  : (SOLE_HOST.includes("design"))
    ? "design"
    : "development";

/**
 * Setup Sentry error and exception tracking
 * 
 * @source: https://docs.sentry.io/platforms/javascript/guides/vue/
 */
if (SOLE_ENV !== "development") {
  Sentry.init({
    Vue,
    dsn: "https://8f1b475b9172442581449fa19eb45777@o1080395.ingest.sentry.io/6086166",
    environment: SOLE_ENV,
    integrations: [new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["design.yoursole.com", "yoursole.com"],
    })],
    tracesSampleRate: 0.25,
    // Ignore/deny list to clear up a bunc of noise that will end up in Sentry
    // mostly due to user extensions and network flakiness
    // @source: https://gist.github.com/Chocksy/e9b2cdd4afc2aadc7989762c4b8b495a
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      'Can\'t find variable: ZiteReader',
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      'Script error.',
      // Avast extension error
      "_avast_submit"
    ],
    denyUrls: [
      // Google Adsense
      /pagead\/js/i,
      // Ignore Google flakiness
      /\/(gtm|ga|analytics)\.js/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Firefox extensions
      /^resource:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ]
  });
}

new Vue({
  router,
  store,
  render: h => h(Homepage)
}).$mount('.SoleApp');
