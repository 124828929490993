import LazyLoad from "vanilla-lazyload";

// core version + navigation, pagination modules:
import Swiper from "swiper";
import { Navigation, Pagination, Autoplay } from 'swiper';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay]);

document.addEventListener('DOMContentLoaded', function() {
  new Swiper("#Index__testimonial", {
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: {
      delay: 2700,
      disableOnInteraction: true,
    },
    preventClicks: false,
    preventClicksPropagation: false,
    speed: 20000,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next-testimonial",
      prevEl: ".swiper-button-prev-testimonial"
    }
  });
  
  if ( window.location.hash == '#contact' ) { $("a[data-target='#contact_us']").click(); }
  
  new Swiper("#ysslider", {
    spaceBetween: 5,
    autoplay: {
      delay: 7000,
      disableOnInteraction: true
    },
    loop: false,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    }
  });
  
  new LazyLoad({
    class_loaded: "loaded"
  });
});

import '~/customMounts/Homepage/homepage';
